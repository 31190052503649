import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import { Link } from 'gatsby';
import logga from '../assets/images/lindgrenloggainverted.png'
import { globalHistory } from "@reach/router"

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  const [onLandingPage, toggleOnLandingPage] = useState(false);
  const [logo, setLogo] = useState(<img className="logga w-16" src={logga} />)
  
  // let onLandingPage = false;
  useEffect(() => {
    const path = globalHistory.location.pathname
    if(path === "/") {
      toggleOnLandingPage(true);
    } else {
      toggleOnLandingPage(false);

    }
    if(onLandingPage) {
      setLogo(<img className="logga w-16 md:w-32" src={logga} />)
    } else {
      setLogo(<img className="logga w-10 md:w-12" src={logga} />)

    }
  }, [onLandingPage])

  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      {logo}
      <h1 className="text-white">
        <Link to="/">Lindgrens Hästcenter</Link>
      </h1>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
      </div>
    </header>
  );
}
