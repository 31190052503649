import React from 'react';
import SocialLinks from './SocialLinks'
import config from '../../config';
export default function Footer() {
  return (
    <footer id="footer">
      <SocialLinks links={config.socialLinks} />
      <ul className="copyright">
        <li>&copy; Lindgrens Hästcenter</li>
      </ul>
    </footer >
  );
}
