import React from 'react'

const types = {
  facebook: {
    style: 'brands',
    icon: 'fab fa-facebook',
    name: 'Facebook',
  },
  twitter: {
    style: 'brands',
    icon: 'fab fa-twitter',
    name: 'Twitter',
  },
  instagram: {
    style: 'brands',
    icon: 'fab fa-instagram',
    name: 'Instagram',
  },
  homepage: {
    style: 'brands',
    icon: 'fas fa-external-link-alt',
    name: 'Hemsida',
  },
  email: {
    style: 'solid',
    icon: 'far fa-envelope',
    name: 'Email',
  }
}
export default function SocialLinks({ links }) {
  return (
    <ul className="icons">
      {links.map(social => {
        let { style, icon, name, url, type } = social;
        if (type) {
          style = types[type].name
          icon = types[type].icon
          name = types[type].name;
        }
        return (
          <li key={url}>
            <a href={url} className="icon" target="_blank">
              <i className={`${style} ${icon}`}></i>
              {/* <span className="label">{name}</span> */}
            </a>
          </li>
        );
      })}
    </ul>
  )
}