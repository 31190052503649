module.exports = {
  siteTitle: 'Lindgrens Hästcenter', // <title>
  manifestName: 'Lindgrens Hästcenter',
  manifestShortName: 'Lindgrens', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.jpg',
  // pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Lindgrens Hästcenter',
  subHeading: 'Det familjära hästcentret med det stora hjärtat',
  // social
  socialLinks: [
    // {
    //   style: 'brands',
    //   icon: 'fab fa-github',
    //   name: 'Github',
    //   url: 'https://github.com/anubhavsrivastava',
    // },
    {
      style: 'brands',
      icon: 'fab fa-facebook',
      name: 'Facebook',
      url: 'https://www.facebook.com/Lindgrens-H%C3%A4stcenter-553965938115522',
    },
    // {
    //   style: 'brands',
    //   icon: 'fab fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/lindgrenshastcenter',
    // },
    {
      style: 'brands',
      icon: 'fab fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/lindgrens_hastcenter',
    },
    {
      style: 'solid',
      icon: 'far fa-envelope',
      name: 'Email',
      url: 'mailto:lindgrenshastcenter@gmail.com',
    },
  ]
};
